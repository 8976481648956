// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"
import { emojisplosion, emojisplosions } from "emojisplosion";

export default class extends Controller {

  connect() {
    // Just one explosion, please.
    emojisplosion({
      emojiCount: 200,
    });

    // https://github.com/JoshuaKGoldberg/emojisplosion
    // går att sätta vilka emojis, mängden, osv.

    // Commence explosions!
    //emojisplosions();

  }
}
