// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"
import SlotMachine from 'slot-machine-gen';
import 'slot-machine-gen/dist/slot-machine.css';


export default class extends Controller {

  connect() {
    const container = document.getElementById("slot-machine")
    const callback = alert('Woop!')
    const options = {}

    const reels = [
    {
      imageSrc: '/assets/reel-strip-179cb7168ce23451855906a30f4b776ba4beafe522b31970f5b8681491541e9e.png',
      symbols: [
        {
          title: 'cherry',
          position: 100,
          weight: 200
        },
        {
          title: 'plum',
          position: 300,
          weight: 6
        },
        {
          title: 'orange',
          position: 500,
          weight: 5
        },
        {
          title: 'bell',
          position: 700,
          weight: 1
        },
        {
          title: 'cherry',
          position: 900,
          weight: 3
        },
        {
          title: 'plum',
          position: 1100,
          weight: 5
        }
      ]
    },
    {
      imageSrc: '/assets/reel-strip-179cb7168ce23451855906a30f4b776ba4beafe522b31970f5b8681491541e9e.png',
      symbols: [
        {
          title: 'cherry',
          position: 100,
          weight: 200
        },
        {
          title: 'plum',
          position: 300,
          weight: 6
        },
        {
          title: 'orange',
          position: 500,
          weight: 5
        },
        {
          title: 'bell',
          position: 700,
          weight: 1
        },
        {
          title: 'cherry',
          position: 900,
          weight: 3
        },
        {
          title: 'plum',
          position: 1100,
          weight: 5
        }
      ]
    },
    {
      imageSrc: '/assets/reel-strip-179cb7168ce23451855906a30f4b776ba4beafe522b31970f5b8681491541e9e.png',
      symbols: [
        {
          title: 'cherry',
          position: 100,
          weight: 200
        },
        {
          title: 'plum',
          position: 300,
          weight: 6
        },
        {
          title: 'orange',
          position: 500,
          weight: 5
        },
        {
          title: 'bell',
          position: 700,
          weight: 1
        },
        {
          title: 'cherry',
          position: 900,
          weight: 3
        },
        {
          title: 'plum',
          position: 1100,
          weight: 5
        }
      ]
    },
    // add more reels ...
    ]

    console.log("slot machine")
    const slotMachine = new SlotMachine(container, reels, callback, options);
  }
}
