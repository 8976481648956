// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"
import { puzzle } from 'jigsaw-puzzle'

export default class extends Controller {

  connect() {

    const P = puzzle({
      element: '#jigsaw',
      image: '/assets/back-51c55fa7f25304d599d6ab144631eb2b55566092fbf76cc44e0daceee1ff4b37.png',
      pieces: { x: 6, y: 4 },
      attraction: 30,  // distance to snap pieces
      size: 0.8,       // ratio of puzzle/canvas
      draggable: true, // move puzzle by dragging canvas
      onComplete: state => {},
      onChange: state => {}
    })



  }
}
