// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"
import { ScratchCard, SCRATCH_TYPE } from 'scratchcard-js'

export default class extends Controller {

  connect(event) {

    const frontImg = this.data.get("frontimage").trim()
    const backImg = this.data.get("backimage").trim()

    const scContainer = document.getElementById('js--sc--container')
    const linkUrl = this.data.get("outlink").trim() //'http://google.com'

    const backgroundHtml = '<a href="'+linkUrl+'" target="_blank"><div class="" id="revealed-container" style="background-image: url(\''+backImg+'\'); height:400px; width:400px;"></div></a>'

    //console.log(backgroundHtml)

    const sc = new ScratchCard('#js--sc--container', {
      scratchType: SCRATCH_TYPE.CIRCLE,
      containerWidth: scContainer.offsetWidth,
      containerHeight: 400,
      imageForwardSrc: frontImg,
      imageBackgroundSrc: backImg,
      htmlBackground: backgroundHtml,
      clearZoneRadius: 30,
      nPoints: 0,
      pointSize: 0,
      callback: function () {
        //alert('Now the window will reload !')
        //window.location.reload()
      }
    })

    // Init
    sc.init().then(() => {
      sc.canvas.addEventListener('scratch.move', () => {
        let percent = sc.getPercent().toFixed(2)
        //console.log(percent)
      })
    }).catch((error) => {
      // image not loaded
      alert(error.message);
    });
  }
}
